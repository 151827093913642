@font-face {
  font-family: "SHOWCARD";
  src: url("assets/SHOWG.TTF");
}

* {
  box-sizing: border-box;
}

html {
  font-family: "Noto Sans JP", sans-serif;
}

body {
  font-family: "Montserrat", sans-serif;
  position: relative;
}

.nav-link {
  color:#bfe1f8;
}

.anton {
  font-family: "Anton", sans-serif;
}

.logo-font {
  font-family: "SHOWCARD", serif;
}

.dancing {
  font-family: "Dancing Script", sans-serif;
}
.rentals {
  /*max-width: 60rem;*/
  width: 100%;
  margin: 1rem auto;
  animation: rentals-appear 1s ease-out forwards;
}

@keyframes rentals-appear {
  from {
    opacity: 0;
    transform: translateY(3rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/*   @media (min-width: 768px) {
  .rentals {
    align-items: center;
    flex-direction: row;
  }
} */

.login {
  animation: login-appear 1s ease-out forwards;
}

@keyframes login-appear {
  from {
    opacity: 0;
    transform: translateY(3rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.max-hei {
  max-height: 90vh;
}

.mainhead {
  padding-top: calc(6rem + 74px);
}

@media (min-width: 992px) {
  .mainhead {
    padding-top: calc(6rem + 104px);
  }
}

.bg-primaryspr {
background-color: #5ad116 !important;
}

.bg-primaryff {
  background-color: #e0af0c !important;
}

.bg-primarySum {
  background-color: #e0af0c !important;
}